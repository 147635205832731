import React, { useState } from "react";
import Image from "gatsby-plugin-sanity-image";
import { Link } from "gatsby";
import { Transition } from "@headlessui/react";
import { XIcon, MenuIcon } from "@heroicons/react/outline";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Menu = ({ logo, items, contactNumber }) => {
	const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
	return (
		<>
			<div className="relative pt-6 px-4 sm:px-6 lg:px-8">
				<nav
					className="relative flex items-center justify-between sm:h-10 lg:justify-start"
					aria-label="Global"
				>
					<div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
						<div className="flex items-start justify-between w-full md:w-auto">
							<Link to="/" className="md:hidden -ml-2" aria-label="Home">
								<Image
									{...logo}
									width={200}
									className="h-10 w-auto sm:h-10 m-2"
									alt="Very Good Support Services Logo"
								/>
							</Link>
							<div className="-mr-2 flex items-center md:hidden">
								<button
									type="button"
									className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
									aria-expanded="false"
									onClick={() => setMobileMenuIsOpen(true)}
								>
									<span className="sr-only">Open main menu</span>
									<MenuIcon className="h-6 w-6" />
								</button>
							</div>
						</div>
					</div>
					<nav className="hidden md:block md:pr-4 md:space-x-8">
						<Link to="/" aria-label="Home">
							<Image
								{...logo}
								width={200}
								className="h-12 w-auto sm:h-10 inline-block mb-0"
								alt="Very Good Support Services Logo"
							/>
						</Link>
						{items
							.sort((a, b) => a.sortOrder - b.sortOrder)
							.map(({ menuItem, menuLink }) => {
								return (
										<AnchorLink
											to={menuLink}
											className=" text-gray-700 hover:text-gray-900"
											key={menuItem}
										>
											{menuItem}
										</AnchorLink>
								);
							})}
					</nav>
				</nav>
			</div>
			<Transition
				show={mobileMenuIsOpen}
				enter="duration-150 ease-out"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="duration-100 ease-in"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
					<div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
						<div className="px-5 pt-4 flex items-center justify-between">
							<div></div>
							<div className="-mr-2">
								<button
									type="button"
									className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
									onClick={() => setMobileMenuIsOpen(false)}
								>
									<span className="sr-only">Close main menu</span>
									<XIcon className="h-6 w-6" />
								</button>
							</div>
						</div>
						<nav className="px-2 pt-2 pb-3 space-y-1 -mt-2">
							<AnchorLink
								to="/"
								onAnchorLinkClick={() => setMobileMenuIsOpen(false)}
								className="block px-3 py-2 rounded-md text-base text-gray-700 hover:text-gray-900 hover:bg-gray-50"
							>
								Home
							</AnchorLink>
							{items.map(({ menuItem, menuLink }) => {
								return (
									<AnchorLink
										to={menuLink}
										onAnchorLinkClick={() => setMobileMenuIsOpen(false)}
										className="block px-3 py-2 rounded-md text-base text-gray-700 hover:text-gray-900 hover:bg-gray-50"
										key={menuItem}
									>
										{menuItem}
									</AnchorLink>
								);
							})}
						</nav>
						<a
							href={`tel:${contactNumber}`}
							className="block w-full px-5 py-3 text-center font-medium text-yellow-600 bg-gray-50 hover:bg-gray-100"
						>
							Call Us
						</a>
					</div>
				</div>
			</Transition>
		</>
	);
};

export default Menu;
